import axios from '@/plugins/axios';

class OverviewApi {
  static fetchConsumerTypeDistribution() {
    return axios({
      url: '/api/stat/overview/patient',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询消费者类型分布',
        },
      },
    });
  }

  static fetchConsumerAgeDistribution() {
    return axios({
      url: '/api/stat/overview/customerage',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询消费者年龄分布',
        },
      },
    });
  }

  static fetchRegionalDistribution() {
    return axios({
      url: '/api/stat/overview/map',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询区域分布',
        },
      },
    });
  }

  static fetchRealTimeDynamic() {
    return axios({
      url: '/api/stat/overview/realtimedata',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询实时动态',
        },
      },
    });
  }

  static fetchRegisterAccount() {
    return axios({
      url: '/api/stat/overview/account',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询总览',
        },
      },
    });
  }

  static fetchSystemIncreased() {
    return axios({
      url: '/api/stat/overview/growthtrend',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询系统增长趋势',
        },
      },
    });
  }
}

export default OverviewApi;

<template>
  <div class="overview-container">
    <app-spacing
      class="overview"
      ref="overview">
      <div
        class="column first-column"
        ref="firstColumn">
        <Card class="row">
          <template slot="title">消费者类型分布</template>
          <ve-pie
            :settings="pieChartSettings"
            :extend="pieChartExtend"
            :height="secondOfOneHeight"
            :width="threeInTenWidth"
            :data="pieChartData"
            dataType="percent"/>
        </Card>
        <Card class="row">
          <template slot="title">使用年龄分布</template>
          <ve-histogram
            :settings="histogramChartSettings"
            :extend="histogramExtend"
            :height="secondOfOneHeight"
            :width="threeInTenWidth"
            :data="histogramChartData"/>
        </Card>
      </div>
      <div
        class="column"
        ref="secondColumn">
        <Card class="row">
          <template slot="title">快视科技全国分布</template>
          <ve-map
            :extend="mapChartExtend"
            :settings="mapChartSettings"
            :height="`${Number.parseInt(oneOfThreeHeight) - 4 + 120}px`"
            :width="`${Number.parseInt(threeInTenWidth) - 38}px`"
            :data="mapChartData" />
          <span class="map-tip">按住鼠标左键并拖动移动位置，使用滚轮放大或缩小地图</span>
        </Card>
        <Card class="row">
          <template slot="title">实时动态</template>
          <vue-seamless-scroll
            :style="{ height: `${Number.parseInt(oneOfThreeHeight) - 4 - 120}px` }"
            :classOption="{
              step:0.3
            }"
            :data="listData"
            class="seamless-scroller">
            <ul
              class="item">
              <li v-for="(item, index) in listData" :key="index">
                {{ item }}
              </li>
            </ul>
          </vue-seamless-scroll>
        </Card>
        <Card class="row">
          <template slot="title">总览</template>
          <div
            :style="{ height: oneOfThreeHeight }"
            class="statistics-content">
            <div
              class="item"
              :key="key"
              v-for="(text, key) in OVERVIEW_TYPE_TEXT">
              <i :class="['icon', `icon-${OVERVIEW_TYPE_ICON[key]}`]" />
              <div class="right">
              <span class="title">
                累计注册{{text}}
              </span>
                <span class="count">
                {{statistics[key]}}
              </span>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div
        class="column"
        ref="thirdColumn">
        <Card class="row">
          <template slot="title">消费者注册增长趋势</template>
          <ve-line
            :settings="lineChartSettings"
            :extend="lineChartExtend"
            :height="thirdOfOneHeight"
            :width="threeInTenWidth"
            :data="lineChartData"/>
        </Card>
        <Card class="row">
          <template slot="title">设备激活增长趋势</template>
          <ve-line
            :settings="lineChartSettings2"
            :extend="lineChartExtend2"
            :height="thirdOfOneHeight"
            :width="threeInTenWidth"
            :data="lineChartData2"/>
        </Card>
        <Card class="row">
          <template slot="title">实时在线（最近1小时数据）</template>
          <div class="online-status">
            <div class="item">
              <span class="title">当前在线人数</span>
              <span class="count">{{onlineUserCount}}</span>
            </div>
            <div class="item">
              <span class="title">当前在线门店</span>
              <span class="count">{{onlineStoreCount}}</span>
            </div>
          </div>
          <vue-seamless-scroll
            :style="{ height: `${Number.parseInt(oneOfThreeHeight) - 78}px` }"
            :data="listData2"
            class="seamless-scroller">
            <ul
              class="item">
              <li v-for="(item, index) in listData2" :key="index">
                {{ item }}
              </li>
            </ul>
          </vue-seamless-scroll>
        </Card>
      </div>
    </app-spacing>
  </div>
</template>

<script>
/* eslint-disable */

import moment from 'moment';
import VePie from 'v-charts/lib/pie.common';
import VeHistogram from 'v-charts/lib/histogram.common';
import VeLine from 'v-charts/lib/line.common';
import VeMap from 'v-charts/lib/map.common';
import vueSeamlessScroll from 'vue-seamless-scroll';
import {
  CARD_HEADER_HEIGHT,
  CARD_CONTENT_SPACING,
  MD_SPACING,
  OVERVIEW_TYPE_TEXT,
  OVERVIEW_TYPE_ICON, OVERVIEW_TYPE,
} from './constants';
import DomUtil from '../../utils/dom.util';
import OverviewApi from './overview.api';

export default {
  name: 'overview',
  components: {
    VePie,
    VeHistogram,
    VeLine,
    VeMap,
    vueSeamlessScroll,
  },
  created() {
    this.$nextTick(this.getContentHeight);
    window.addEventListener('resize', this.resizeHandle);
    this.fetchData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandle);
  },
  computed: {
    secondOfOneHeight() {
      const sourceValue = (this.contentHeight * 10000)
        / 20000 - CARD_HEADER_HEIGHT - CARD_CONTENT_SPACING - (MD_SPACING / 2);
      return `${Math.floor(sourceValue)}px`;
    },
    thirdOfOneHeight() {
      const sourceValue = (this.contentHeight * 10000)
        / 30000 - CARD_HEADER_HEIGHT - CARD_CONTENT_SPACING - ((MD_SPACING * 2) / 3);
      return `${Math.floor(sourceValue)}px`;
    },
    twoOfThreeHeight() {
      const sourceValue = (this.contentHeight * 10000)
        * (6.667 / 100000) - CARD_HEADER_HEIGHT - CARD_CONTENT_SPACING - (MD_SPACING / 2);
      return `${Math.floor(sourceValue)}px`;
    },
    oneOfThreeHeight() {
      const sourceValue = (this.contentHeight * 10000)
        * (3.334 / 100000) - CARD_HEADER_HEIGHT - CARD_CONTENT_SPACING - (MD_SPACING / 2);
      return `${Math.floor(sourceValue)}px`;
    },
    fourInTenWidth() {
      const sourceValue = (this.contentWidth * 4) / 10
        - CARD_CONTENT_SPACING - ((MD_SPACING * 2) / 3);
      return `${Math.floor(sourceValue)}px`;
    },
    threeInTenWidth() {
      const sourceValue = (this.contentWidth * 3.333) / 10
        - CARD_CONTENT_SPACING - ((MD_SPACING * 2) / 3);
      return `${Math.floor(sourceValue)}px`;
    },
    pieChartSettings() {
      return {
        // eslint-disable-next-line radix
        offsetY: Number.parseInt(this.secondOfOneHeight) / 2,
        radius: 70,
        roseType: 'area',
        itemStyle: {
          borderRadius: 8,
        },
      };
    },
  },
  data() {
    return {
      OVERVIEW_TYPE_TEXT,
      OVERVIEW_TYPE_ICON,
      statistics: {
        [OVERVIEW_TYPE.USERS]: 0,
        [OVERVIEW_TYPE.OPTOMETRISTS]: 0,
        [OVERVIEW_TYPE.CHANNELS]: 0,
        [OVERVIEW_TYPE.STORES]: 0,
      },
      resizeHandle: DomUtil.debounce(this.getContentHeight, 50),
      contentHeight: 0,
      contentWidth: 0,
      pieChartExtend: {
        label: {
          formatter: '{d}%',
        },
        tooltip: {
          formatter: '{d}%',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'bottom',
          textStyle: {
            color: '#fff',
          },
        },
      },
      pieChartData: {
        columns: ['name', 'value'],
        rows: [{ name: '近视', value: 0 }, { name: '尚未近视', value: 0 }, { name: '弱视', value: 0 }],
      },
      histogramChartSettings: {
        // stack: {
        //   stackGroup: ['female', 'male'],
        // },
        legendName: { male: '男性', female: '女性' },
        labelMap: { male: '男性', female: '女性' },
      },
      histogramExtend: {
        grid: {
          left: 24,
        },
        yAxis: {
          name: '人',
          nameLocation: 'center',
          nameGap: 30,
          axisLabel: {
            formatter: (v) => Math.abs(v),
          },
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        tooltip: {
          formatter: (v) => {
            const [first, second] = v;
            return `${first.axisValue}<br />${first.seriesName}: ${Math.abs(first.value)}<br />${second.seriesName}: ${Math.abs(second.value)}`;
          },
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'bottom',
          textStyle: {
            color: '#fff',
          },
        },
      },
      histogramChartData: {
        columns: ['name', 'female', 'male'],
        rows: [
          { name: '0-5岁', female: 0, male: 0 },
          { name: '6-10岁', female: 0, male: 0 },
          { name: '11-15岁', female: 0, male: 0 },
          { name: '16-20岁', female: 0, male: 0 },
          { name: '21-25岁', female: 0, male: 0 },
          { name: '26-30岁', female: 0, male: 0 },
          { name: '31-35岁', female: 0, male: 0 },
          { name: '36-40岁', female: 0, male: 0 },
          { name: '41-45岁', female: 0, male: 0 },
          { name: '46-50岁', female: 0, male: 0 },
          { name: '51-55岁', female: 0, male: 0 },
          { name: '56-60岁', female: 0, male: 0 },
          { name: '61-65岁', female: 0, male: 0 },
          { name: '66-70岁', female: 0, male: 0 },
          { name: '71-75岁', female: 0, male: 0 },
          { name: '75岁+', female: 0, male: 0 },
        ],
      },
      lineChartSettings: {
        area: true,
        legendName: { month: '月份', value: '人数' },
        labelMap: { month: '月份', value: '人数' },
      },
      lineChartExtend: {
        grid: {
          left: 24,
        },
        yAxis: {
          name: '人',
          nameLocation: 'center',
          nameGap: 40,
          nameTextStyle: {
            align: 'center',
            width: 100,
          },
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          minInterval: 1,
        },
        xAxis: {
          minInterval: 1,
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          formatter: '{c}人',
        },
      },
      lineChartData: {
        columns: ['month', 'value'],
        rows: [
          { month: '1月', value: 0 },
          { month: '2月', value: 0 },
          { month: '3月', value: 0 },
          { month: '4月', value: 0 },
          { month: '5月', value: 0 },
          { month: '6月', value: 0 },
        ],
      },
      lineChartSettings2: {
        area: true,
        legendName: { month: '月份', value: '件数' },
        labelMap: { month: '月份', value: '件数' },
      },
      lineChartExtend2: {
        grid: {
          left: 24,
        },
        yAxis: {
          name: '件',
          nameLocation: 'center',
          nameGap: 30,
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          minInterval: 1,
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          formatter: '{c}件',
        },
      },
      lineChartData2: {
        columns: ['month', 'value'],
        rows: [
          { month: '1月', value: 0 },
          { month: '2月', value: 0 },
          { month: '3月', value: 0 },
          { month: '4月', value: 0 },
          { month: '5月', value: 0 },
          { month: '6月', value: 0 },
        ],
      },
      mapChartExtend: {
        legend: {
          textStyle: {
            color: '#fff',
          },
        },
      },
      mapChartSettings: {
        zoom: 1,
        roam: true,
        scaleLimit: { max: 10, min: 1 },
        label: {
          show: true,
          position: 'insideTopLeft',
          offset: [-20, -20],
        },
        legendName: {
          city: '城市', stores: '门店', devices: '设备', users: '用户',
        },
        labelMap: {
          city: '城市', stores: '门店', devices: '设备', users: '用户',
        },
        itemStyle: {
          normal: {
            areaColor: '#b3a3a3',
            borderColor: '#fff',
          },
          emphasis: {
            areaColor: '#cbab89',
            borderColor: '#fff',
          },
        },
      },
      mapChartData: {
        columns: ['city', 'stores', 'devices', 'users'],
        rows: [],
      },
      listData: [],
      listData2: [],
      onlineUserCount: 0,
      onlineStoreCount: 0,
    };
  },
  methods: {
    getContentHeight() {
      const el = this.$refs.overview?.$el;
      this.contentHeight = (el.clientHeight || 0) < 900 ? 900 : (el.clientHeight || 900);
      this.contentWidth = (el.clientWidth || 0) < 1524 ? 1524 : (el.clientWidth || 1524);
    },
    async fetchData() {
      const consumerTypeData = await OverviewApi.fetchConsumerTypeDistribution();
      const consumerTypeRows = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const k in consumerTypeData) {
        consumerTypeRows.push({ name: k, value: Number.parseFloat(consumerTypeData[k]) });
      }
      this.pieChartData.rows = consumerTypeRows;
      const consumerAgeData = await OverviewApi.fetchConsumerAgeDistribution();
      const consumerAgeRows = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const k in consumerAgeData) {
        consumerAgeRows.push({
          name: k,
          // eslint-disable-next-line radix
          female: Number.parseInt(consumerAgeData[k]['女']),
          // eslint-disable-next-line radix
          male: Number.parseInt(consumerAgeData[k]['男']),
        });
      }
      this.histogramChartData.rows = consumerAgeRows;
      const regionalData = await OverviewApi.fetchRegionalDistribution();
      const regionalRows = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const k in regionalData) {
        regionalRows.push({
          city: k.replace('省', '').replace('市', ''),
          stores: regionalData[k].store_count,
          devices: regionalData[k].product_ct,
          users: regionalData[k].customer_ct,
        });
      }
      this.mapChartData.rows = regionalRows;
      const registerAccountData = await OverviewApi.fetchRegisterAccount();
      this.statistics = {
        [OVERVIEW_TYPE.USERS]: registerAccountData?.customer_ct,
        [OVERVIEW_TYPE.OPTOMETRISTS]: registerAccountData?.sgs_ct,
        [OVERVIEW_TYPE.CHANNELS]: registerAccountData?.channel_ct,
        [OVERVIEW_TYPE.STORES]: registerAccountData?.store_ct,
      };
      const {
        product_active: deviceIncreasedData = [],
        customer: consumerIncreasedData = [],
      } = await OverviewApi.fetchSystemIncreased();
      const deviceIncreasedRows = [];
      const consumerIncreasedRows = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const k in deviceIncreasedData) {
        deviceIncreasedRows.push({
          month: `${moment(deviceIncreasedData[k].stat_date).format('M')}月`,
          value: Number.parseFloat(deviceIncreasedData[k].count),
        });
      }
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const k in consumerIncreasedData) {
        consumerIncreasedRows.push({
          month: `${moment(consumerIncreasedData[k].stat_date).format('M')}月`,
          value: Number.parseFloat(consumerIncreasedData[k].count),
        });
      }
      this.lineChartData2.rows = deviceIncreasedRows;
      this.lineChartData.rows = consumerIncreasedRows;
      const {
        realtime_msg_list: listData = [],
        online_user_count: onlineUserCount = 0,
        online_store_count: onlineStoreCount = 0,
        online_store_list: listData2 = [],
      } = await OverviewApi.fetchRealTimeDynamic();
      this.listData = listData;
      this.listData2 = listData2;
      this.onlineUserCount = onlineUserCount;
      this.onlineStoreCount = onlineStoreCount;
    },
  },
};
</script>

<style lang="less" scoped>
.overview-container {
  height: 100%;
  background: #000;
}
.overview {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  padding: 16px;
  height: calc(~"100% - 20px");
  width: 100%;
  overflow: auto;
  > .column {
    height: 100%;
    display: flex;
    flex-direction: column;
    > .row {
      position: relative;
      border-color: #021425;
      background-color: #021425;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      /deep/ .ivu-card-head {
        font-weight: 800;
      }
      &:not(:first-child) {
        margin-top: @spacingMD;
      }

      color: #fff;
      /deep/ .ivu-card-head {
        background-color: #000;
        border-bottom-color: #000;
        color: #fff;
        font-weight: 800;
      }

      .map-tip {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 100%;
        text-align: center;
        opacity: 0.5;
      }
    }
  }
}

.statistics-content {
  display: flex;
  flex-wrap: wrap;
  .item {
    display: flex;
    align-items: center;
    width: 50%;
    .icon {
      font-size: 50px;
    }
    .right {
      font-weight: 800;
      margin-left: @spacingMD;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 14px;
      }
      .count {
        font-size: 20px;
      }
    }
  }
}

.seamless-scroller {
  overflow: hidden;
}

.online-status {
  font-size: 14px;
  font-weight: 800;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
</style>

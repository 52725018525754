export const CARD_HEADER_HEIGHT = 43;

export const CARD_CONTENT_SPACING = 32;

export const MD_SPACING = 16;

export const OVERVIEW_TYPE = {
  USERS: 'USERS',
  OPTOMETRISTS: 'OPTOMETRISTS',
  CHANNELS: 'CHANNELS',
  STORES: 'STORES',
};

export const OVERVIEW_TYPE_TEXT = {
  [OVERVIEW_TYPE.USERS]: '用户',
  [OVERVIEW_TYPE.OPTOMETRISTS]: '视光师',
  [OVERVIEW_TYPE.CHANNELS]: '渠道',
  [OVERVIEW_TYPE.STORES]: '门店',
};

export const OVERVIEW_TYPE_ICON = {
  [OVERVIEW_TYPE.USERS]: 'users',
  [OVERVIEW_TYPE.OPTOMETRISTS]: 'doctors',
  [OVERVIEW_TYPE.CHANNELS]: 'channels',
  [OVERVIEW_TYPE.STORES]: 'stores',
};

class DomUtil {
  static debounce(
    fn = () => {},
    delay = 300,
  ) {
    let timeout;
    return function callback() {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, arguments);
      }, delay);
    };
  }

  static throttle(
    fn = () => {},
    delay = 300,
  ) {
    let previous = 0;
    return function callback() {
      const now = Date.now();
      if (now - previous > delay) {
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, arguments);
        previous = now;
      }
    };
  }
}

export default DomUtil;
